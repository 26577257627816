
import { defineComponent, PropType } from '@vue/composition-api';
import { Checkbox } from '@fc/angie-ui';
import { IFilter } from '~/composables/useFilterSort';

export default defineComponent({
  name: 'MobileFilterMenu',
  components: {
    Checkbox,
  },
  props: {
    filters: {
      type: Array as PropType<IFilter[]>,
      default: () => [],
    },
  },
});
